import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

import AppBar from './AppBar';
import Drawer from './Drawer';
// import Footer from './Footer';

import lightTheme from '../../utils/theme/light';
import darkTheme from '../../utils/theme/dark';

const lightModeTheme = createTheme(lightTheme);
const darkModeTheme = createTheme(darkTheme);

export default function Layout(props) {
	const { isMobile, cacheUserName } = props;
	const [lightMode, setLightMode] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(1);

	const { pathname } = useLocation();

	useEffect(() => {
		if (pathname.includes('/dashboard') || pathname === "/") {
			setSelectedIndex(0);
		}
		else if (pathname.includes('/swagitems')) {
			setSelectedIndex(1);
		}
		else if (pathname.includes('/inventory')) {
			setSelectedIndex(2);
		}
		else if (pathname.includes('/companies')) {
			setSelectedIndex(3);
		}
		else if (pathname.includes('/contacts')) {
			setSelectedIndex(4);
		}
		else if (pathname.includes('/disbursements')) {
			setSelectedIndex(5);
		}
		else if (pathname.includes('/financial')) {
			setSelectedIndex(6);
		}
		else if (pathname.includes('/users')) {
			setSelectedIndex(7);
		}
		else if (pathname.includes('/settings')) {
			setSelectedIndex(8);
		}
		else {
			setSelectedIndex(null);
		}
	},
		[pathname],
	);

	let initialOpen;
	if (isMobile) {
		initialOpen = false;
	}
	else {
		initialOpen = true;
	}

	const [open, setOpen] = useState(initialOpen);
	const toggleDrawer = () => {
		setOpen(!open);
	};
	const drawerWidth = 240;

	return (
		<ThemeProvider theme={lightMode ? lightModeTheme : darkModeTheme}>
			<Box sx={{ display: 'flex' }}>
				<CssBaseline />
				<AppBar isMobile={isMobile} open={open} drawerWidth={drawerWidth} toggleDrawer={toggleDrawer} cacheUserName={cacheUserName} />
				<Drawer isMobile={isMobile} drawerWidth={drawerWidth} open={open} toggleDrawer={toggleDrawer} selectedIndex={selectedIndex} />
				<Outlet />
			</Box>
		</ThemeProvider>
	);
}