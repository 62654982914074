import React, { useState } from 'react';
import { useApolloClient, gql, useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Snackbar from '@mui/material/Snackbar';

import { Save as SaveIcon } from 'lucide-react';

const MODIFY_PREFERENCES = gql`
    mutation ModifyPreferences($username: String, $email: String) {
        modifyPreferences(username: $username, email: $email){
            responseCode
            responseText
            actionType
            objectType
            objectId
		}
	}
`;

const USER_PREFERENCES = gql`
    query userPreferencesQuery {
        cacheUserName @client
		cacheUserEmail @client
    }
`;

export default function Preferences(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [serverErr, setServerErr] = useState(false);
    const [errCode, errCodeSet] = useState(false);
    const client = useApolloClient();

    const { data } = props;

    const cacheUserName = data?.cacheUserName;
    const cacheUserEmail = data?.cacheUserEmail;

    const [openAlert, setOpenAlert] = useState(false);

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    const [usernameErr, setUsernameErr] = useState(false);
    const [username, usernameSet] = useState(cacheUserName);
    const handleChangeUsername = (event) => {
        usernameSet(event.target.value);
    };

    const [emailErr, setEmailErr] = useState(false);
    const [email, emailSet] = useState(cacheUserEmail);
    const handleChangeEmail = (event) => {
        emailSet(event.target.value);
    };

    const updateFormData = () => {
        const formData = {};
        let formValid = true;

        setUsernameErr(false);
        setEmailErr(false);
        errCodeSet(false);

        const containBox = document.getElementById("containBox");
        const scrollToError = () => { containBox.scrollIntoView({ behavior: "smooth" }); };

        if (!username || username === "") {
            setUsernameErr(true);
            formValid = false;
        }

        if (!email || email === "") {
            setEmailErr(true);
            formValid = false;
        }

        if (formValid) {
            setServerErr(false);
            formData.username = username;
            formData.email = email;
            setIsLoading(true);
            updateExec({ variables: { ...formData } });
        }
        else {
            scrollToError();
            if (!errCode) {
                errCodeSet(true);
            }
        }

    }

    const [updateExec] = useMutation(MODIFY_PREFERENCES, {

        onCompleted: (setData) => {
            setIsLoading(false);
            const containBox = document.getElementById("containBox");
            const scrollToError = () => { containBox.scrollIntoView({ behavior: "smooth" }); };
            if (setData && setData?.modifyPreferences?.responseCode === "200") {

                client.writeQuery({
					query: USER_PREFERENCES,
					data: {
						cacheUserName: username,
						cacheUserEmail: email
					},
				});

                setOpenAlert(true);
            }
            else {
                console.log(`err`);
                setServerErr(setData?.modifyPreferences?.responseText);
                scrollToError();
            }
        },
        onError: (setError) => {
            const containBox = document.getElementById("containBox");
            const scrollToError = () => { containBox.scrollIntoView({ behavior: "smooth" }); };
            setIsLoading(false);
            setServerErr(`There was an error processing your request.`);
            scrollToError();
        }
    });

    return (
        <Box id="containBox">
            {isLoading &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress sx={{ color: '#c9fb00' }} thickness={1} size={60} /></Backdrop>
            }

            <Snackbar
                open={openAlert}
                anchorOrigin={{ vertical:'top', horizontal:'center' }}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
            >
                <Alert severity="success">
                    <AlertTitle>Preferences Updated Successfully</AlertTitle>
                </Alert>
            </Snackbar>

            {serverErr &&
                <Grid id="serverErrorInfo" container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px' }}>
                    <Grid item>
                        <Alert severity="error" variant="outlined">
                            <AlertTitle>Error</AlertTitle>
                            {serverErr}
                        </Alert>
                    </Grid>
                </Grid>
            }

            {errCode &&
                <Grid id="errorInfo" container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px' }}>
                    <Grid item>
                        <Alert severity="error" variant="outlined">
                            <AlertTitle>Error</AlertTitle>
                            Please resolve the form errors below!
                        </Alert>
                    </Grid>
                </Grid>
            }

            <Typography variant="h5">Preferences</Typography>

            <Stack component="form" spacing={2} autoComplete="off" sx={{ '& > :not(style)': { padding: '20px', paddingBottom: '30px' } }}>

                <FormControl sx={{ marginTop: '30px', maxWidth: '500px' }} error={usernameErr} variant="standard">
                    <InputLabel htmlFor="username">Username</InputLabel>
                    <Input
                        id="username"
                        type="text"
                        value={username} onChange={handleChangeUsername}
                        fullWidth
                        label="Username"
                    />
                </FormControl>

                <FormControl sx={{ marginTop: '30px', maxWidth: '500px' }} error={emailErr} variant="standard">
                    <InputLabel htmlFor="email">Email</InputLabel>
                    <Input
                        id="email"
                        type="text"
                        value={email} onChange={handleChangeEmail}
                        fullWidth
                        label="Email"
                    />
                </FormControl>

            </Stack>
            <Button aria-label="save form" variant="outlined" onClick={updateFormData} endIcon={<SaveIcon strokeWidth={1} size={16} />}>Update Preferneces</Button>

        </Box >
    );
}