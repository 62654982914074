import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { Plus as PlusIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from 'lucide-react';

import { dateOptionsMidTime } from '../../utils/dateoptions';

let displayImages = [];

export default function DetailDisplay(props) {
    const { data, noteDetail, createNote, isMobile, b2Auth, businessRid } = props;
    const theme = useTheme();
    let navigate = useNavigate();

    const [imgSrc, setImgSrc] = useState("");
    const [imgCount, setImgCount] = useState(0);
    const [imgii, setImgii] = useState(1);
    const [imgiiDisplay, setImgiiDisplay] = useState(1);

    const rid = data?.persondetail[0].result?.rid;
    const clientRid = data?.persondetail[0].result.client?.rid;
    const clientName = data?.persondetail[0].result.client?.name;
    const persontype = data?.persondetail[0].result.persontype;
    const firstName = data?.persondetail[0].result.firstName;
    const lastName = data?.persondetail[0].result.lastName;
    const email = data?.persondetail[0].result.email;
    const jobTitle = data?.persondetail[0].result.jobTitle;
    const phoneNumber = data?.persondetail[0].result.phoneNumber;
    const address = data?.persondetail[0].result.address;
    const addressTwo = data?.persondetail[0].result.addressTwo;
    const city = data?.persondetail[0].result.city;
    const stateRegion = data?.persondetail[0].result.stateRegion;
    const postalCode = data?.persondetail[0].result.postalCode;
    const country = data?.persondetail[0].result.country;
    const crmId = data?.persondetail[0].result.crmId;
    const status = data?.persondetail[0].result.status;
    const details = data?.persondetail[0].result.details;
    // const images = data?.persondetail[0].result.images;
    const notes = data?.persondetail[0].notes;
    const disbursements = data?.persondetail[0].disbursements;

    const currentImages = data?.persondetail[0].result.images;
    if(currentImages && currentImages !== "[]"){
        const displayImagesPreSort = JSON.parse(currentImages);
        try{
            displayImages = displayImagesPreSort.sort((a, b)=>{return a.displayOrder - b.displayOrder;});
        }
        catch{
            displayImages = [];
        }
    }

    useEffect(() => {
        if (currentImages && currentImages !== "[]") {
            setImgCount(displayImages.length);
            const imgSrcVal = displayImages[0]?.["key"];
            if (imgSrcVal) {
                setImgSrc(`https://f004.backblazeb2.com/file/swagmanager/docs/swag/clients/${businessRid}/images/person_${rid}_m_${imgSrcVal}?Authorization=${b2Auth}`);
            }
        }
    }, [currentImages]);

    const handleImgChange = (action) => {
        if(imgCount > 1){
            if (currentImages && currentImages !== "[]" && imgCount > 0) {
                if (action === "prev"){
                    if (imgii === 0){
                        setImgiiDisplay(1);
                        setImgii(imgCount - 1);}
                    else if (imgii < 1){
                        setImgii(imgCount - 1);}
                    else {
                        setImgiiDisplay(imgii + 1);
                        setImgii(imgii - 1);}
                    getImgSrc(imgii);
                }
                else {
                    if (imgii === (imgCount-1) || imgii >= imgCount){
                        setImgiiDisplay(imgCount);
                        setImgii(0);}
                    else {
                        setImgiiDisplay(imgii + 1);
                        setImgii(imgii + 1);}
                    getImgSrc(imgii);
                }
            }
        }
    }

    const getImgSrc = (ii) => {
        const imgSrcVal = displayImages[ii]?.["key"];
        if (imgSrcVal) {
            setImgSrc(`https://f004.backblazeb2.com/file/swagmanager/docs/swag/clients/${businessRid}/images/person_${rid}_m_${imgSrcVal}?Authorization=${b2Auth}`);
        }
    }

    return (
        <Fragment>
            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>

                <Grid container direction="row" justifyContent="" alignItems="center">
                    <Grid item>
                        {/* <Avatar alt="contact" sx={theme.customStyles.detailImg} /> */}

                        <Card sx={isMobile ? {minWidth:"50px",width:'100%'} : {minWidth:275}}>
                            <CardContent display="flex">
                                <Box sx={{margin:"auto", maxWidth: '400px', width: '100%'}}>
                                    {imgSrc ? 
                                        <Box sx={{width: '100%', minWidth: '180px', height: '100%', minHeight: '100px'}}>
                                            <img src={imgSrc} alt={`img`} width="100%" height="100%" />
                                        </Box>
                                        : 
                                        <Box sx={{ width: '100%', minWidth: '180px', height: '100%', minHeight: '100px'}}></Box>
                                    }
                                    {/* <Box><Typography>{imgDescription}</Typography></Box> */}
                                </Box>
                            </CardContent>
                            <CardActions>
                                <Grid container direction="row" alignItems="flex-end" justifyContent="space-between">
                                    <IconButton color="primary" onClick={()=>handleImgChange('prev')} aria-label="previous image" sx={{border: `${theme.customStyles.iconButtonOutlined.border}`}}>
                                        <ChevronLeftIcon />
                                    </IconButton>
                                    
                                    {imgCount > 0 ? 
                                        <Typography>Image <b>{imgiiDisplay}</b> of <b>{imgCount}</b></Typography> 
                                        : 
                                        <Typography>No images found</Typography>
                                    }
                                    
                                    <IconButton color="primary" onClick={()=>handleImgChange('next')}aria-label="next image" sx={{border: `${theme.customStyles.iconButtonOutlined.border}`}}>
                                        <ChevronRightIcon />
                                    </IconButton>
                                </Grid>
                            </CardActions>
                        </Card>


                    </Grid>
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Name</Box>
                                    <Box sx={theme.customStyles.infoItemContentLg}>{lastName}, {firstName}</Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Company</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>
                                        <Button aria-label={`company ${clientName} detail`} onClick={()=>navigate(`/companies/detail/${clientRid}`)}>{clientName}</Button>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Title</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{jobTitle}</Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Status</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{status}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Type</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{persontype}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>CRM ID</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{crmId}</Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Email</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{email}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Phone</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{phoneNumber}</Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Address</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{address}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Address Two</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{addressTwo}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>City</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{city}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>State / Region</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{stateRegion}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Postal Code</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{postalCode}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Country</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{country}</Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Paper>

            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item>Disbursements</Grid>
                    <Grid item>
                        <Button aria-label="add disbursement" variant="outlined" onClick={()=>{}} endIcon={<PlusIcon strokeWidth={1} />}>Add Disbursement</Button>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Manufacturer</TableCell>
                                <TableCell>Item</TableCell>
                                <TableCell>Dest. Type</TableCell>
                                <TableCell>Destination</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Updated</TableCell>
                                <TableCell>User</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {disbursements?.length > 0 ?
                                disbursements.map((row) => {
                                    const updatedFormatted = (new Date(row.updated)).toLocaleDateString(undefined, dateOptionsMidTime);
                                    return (
                                        <TableRow hover key={row.rid}>
                                            <TableCell>
                                                <Avatar alt={row.rid} src="" />
                                            </TableCell>
                                            <TableCell>{row.swag?.manufacturer}</TableCell>
                                            <TableCell>{row.swag?.name}</TableCell>
                                            <TableCell>{row.destinationtype}</TableCell>
                                            <TableCell>{row.destination}</TableCell>
                                            <TableCell>{row.status}</TableCell>
                                            <TableCell>{row.quantity}</TableCell>
                                            <TableCell>{updatedFormatted}</TableCell>
                                            <TableCell>{row.updatedBy.username}</TableCell>
                                        </TableRow>
                                    );
                                })
                                :
                                <TableRow>
                                    <TableCell colSpan="100%">No Results</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item>Notes</Grid>
                    <Grid item>
                        <Button aria-label="add note" variant="outlined" onClick={createNote} endIcon={<PlusIcon strokeWidth={1} />}>Add Note</Button>
                    </Grid>
                </Grid>
                
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Severity</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Content</TableCell>
                                <TableCell>Updated</TableCell>
                                <TableCell>User</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {notes?.length > 0 ?
                                notes.map((row) => {
                                    const updatedFormatted = (new Date(row.updated)).toLocaleDateString(undefined, dateOptionsMidTime);
                                    // const severity = row.severity -- info, warning, alert, request
                                    return (
                                        <TableRow hover key={row.rid} onClick={()=>noteDetail(row.rid)} sx={{cursor: 'pointer'}}>
                                            <TableCell>{row.severity}</TableCell>
                                            <TableCell>{row.notetype}</TableCell>
                                            <TableCell>{row.status}</TableCell>
                                            <TableCell>{row.title}</TableCell>
                                            <TableCell>{row.content}</TableCell>
                                            <TableCell>{updatedFormatted}</TableCell>
                                            <TableCell>{row.updatedBy.username}</TableCell>
                                        </TableRow>
                                    );
                                })
                                :
                                <TableRow>
                                    <TableCell colSpan="100%">No Results</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

        </Fragment>
    );
}