const darkTheme = {

    //#ffd5af - #596886 - #052b4a (blue/gray with peach accent)
    //#155263 - #1e758d - #0c2f39 - #CBB26B - #d8c691 - #be9e45 (teal/navy with gold)

    // fontFamily: [
    //     'IBM Plex Sans',
    //     '"Source Sans 3"',
    //     '"Segoe UI"',
    //     'Open Sans',
    //     'Roboto',
    //     '"Helvetica Neue"',
    //     'Arial',
    //     'sans-serif',
    // ].join(','),

    palette: {
        mode: 'dark',
        primary: {
            main: '#fff',
        },
        background: {
            paper: '#151517',
            default: '#2d2e32',
        },
    },
    typography: {
        fontFamily: 'Open Sans, Source Sans Pro, IBM Plex Sans, Raleway, Arial',
        textDecoration: 'none'
    },
    customStyles: {
        drawerText: {
            fontSize: '14px',
            '&:hover': {
                color: '#fff',
            },
        },
        drawerIcon: {
            // color: 'yellow',
            size: '20',
            strokeWidth: '1.5',
            // '&:hover': {
            //     color: 'red',
            // },
        },
        iconButtonOutlined: {
            border: '1px solid #cfcfcf'
        },
        // imgPlaceholder: {
        //     backgroundColor: 'black'
        // },
        dividerColor: {
            borderColor: '#707070'
        },
        dividerPadding: {
            paddingTop: '16px',
            paddingBottom: '16px',
            
        },
        dividerMargin: {
            marginBottom: '34px'
        },
        hsButton: {
            color: '#ff7a59',
            border: '1px solid #ff7a59',
            fontSize: '18px',
            textTransform: 'none',
            '&:hover': {
                color: '#fff',
            },
        },
        disconButton:{
            fontSize: '16px',
            textTransform: 'none',
        },
        collpaseTopRow: {
            '& > *': { borderBottom: 'unset' },
            cursor: 'pointer'
        },
        selectedCollpaseTopRow: {
            '& > *': { borderBottom: 'unset' },
            cursor: 'pointer',
            // background: 'red',
            borderSpacing: '12px'
        },
        selectedCollpaseRow: {
            // background: 'red',
        },
        collapseSpacer: {
            background: '#2d2e32',
        },

        appBarMenuItem: {
            fontSize: '14px',
            color: '#f9f9f9',
            paddingTop: '10px',
            paddingBottom: '10px',
            '&:hover': {
                color: '#fff',
            },
        },
        appBarMenuIcon: {
            color: '#f9f9f9',
            size: '20',
            strokeWidth: '1.5',
            '&:hover': {
                color: '#000',
            },
        },
        infoItem: {
            // border:'1px solid white',
            marginLeft: '10px', marginRight: '10px', marginTop: '10px', marginBottom: '10px',
            paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px', paddingBottom: '10px'
        },
        infoItemTitle: {
            fontSize: '.875rem',
            textTransform: 'uppercase',
            color: '#ccc',
        },
        infoItemContent: {
            color: '#fff',
        },
        infoItemContentLg: {
            color: '#fff',
            fontSize: '2rem'
        },
        detailImg: {
            width: 200,
            height: 200
        }
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    background: 'rgb(45, 46, 50)',
                    border: '0px'
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#2d2e32',
                    borderBottom: '1px solid rgba(255, 255, 255, 0.12)'
                }
            }
        },
        MuiAvatar:{
            styleOverrides:{
                img:{
                    objectFit: 'contain'
                }
            }
        },
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: '#ccc',
                    textTransform: 'none',
                    borderLeft: '3px solid rgba(0, 0, 0, 0)',
                    '&:hover': {
                        background: '#151517',
                        borderLeft: '3px solid #fff',
                        color: '#fff',
                    },

                    "&.Mui-selected": {
                        background: '#2d2e32',
                        borderLeft: '3px solid #fff',
                        color: '#c9fb00',
                        '&:hover': {
                            background: '#151517',
                            borderLeft: '3px solid #F9F9F9',
                            color: '#c9fb00'
                        },
                    },
                },
            },
        },

        MuiAlert: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    border: '1px solid green'
                }
            }
        },

        MuiTableCell: {
            styleOverrides: {
                root: {
                    // background:'red',
                    '&.MuiTableCell-head': {
                        background: '#151517',
                    },
                }
            }
        },

        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit'
                }
            }
        },

    },
};

export default darkTheme;