import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import LogoGreen from '../../img/logo-green.svg';
import TextGrey from '../../img/text-grey.svg';

import { Menu as MenuIcon } from 'lucide-react';

import AppBarMenu from './AppBarMenu';

export default function AppBar(props) {
    const { isMobile, drawerWidth, open, toggleDrawer, cacheUserName } = props;

    const AppBarBuild = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    return (
        <AppBarBuild position="fixed" open={open} elevation={0}>
            <Toolbar
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                }}
            >
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{
                        marginRight: `${isMobile ? `12px` : `36px`}`,
                        ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon strokeWidth={1} />
                </IconButton>

                {isMobile ?
                    <Fragment>
                        <Box component={Link} to="../" aria-label="Go to homepage" sx={{ flexGrow: 1 }}>
                            <img
                                src={LogoGreen}
                                alt="swag manager by lucky mallard logo"
                                loading="lazy"
                                height="30px"
                            />
                        </Box>
                        <AppBarMenu uname={cacheUserName} isMobile={isMobile} />
                    </Fragment>
                    :
                    <Fragment>
                        <Box component={Link} to="../" aria-label="Go to homepage" sx={{ flexGrow: 1 }}>
                            <img
                                src={LogoGreen}
                                alt="swag manager by lucky mallard logo"
                                loading="lazy"
                                height="30px"
                            />
                            <span style={{marginLeft: '12px'}}>
                                <img
                                    src={TextGrey}
                                    alt="swag manager by lucky mallard logo text"
                                    loading="lazy"
                                    height="30px"
                                />
                            </span>
                        </Box>
                        <AppBarMenu uname={cacheUserName} isMobile={isMobile} />
                    </Fragment>
                }

            </Toolbar>
        </AppBarBuild>
    );
}