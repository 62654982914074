import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Link } from "react-router-dom";
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { ChevronLeft as ChevronLeftIcon, Shirt as ShirtIcon, LayoutDashboard as LayoutDashboardIcon, Building as BuildingIcon, Contact as ContactIcon, Sheet as SheetIcon, Users as UsersIcon, Settings as SettingsIcon, Package as PackageIcon, Briefcase as BriefcaseIcon } from 'lucide-react';

export default function Drawer(props) {
    const { isMobile, drawerWidth, open, toggleDrawer, selectedIndex } = props;
    const theme = useTheme();

    // const [selectedIndex, setSelectedIndex] = useState(1);

    const handleListItemClick = (event, index) => {
    //   setSelectedIndex(index);
    };

    const DrawerBuild = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            '& .MuiDrawer-paper': {
                position: 'relative',
                whiteSpace: 'nowrap',
                width: drawerWidth,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                boxSizing: 'border-box',
                ...(!open && isMobile && {
                    display: 'none',
                    overflowX: 'hidden',
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                    width: theme.spacing(7),
                    [theme.breakpoints.up('sm')]: {
                        width: theme.spacing(7),
                    },
                }),

                ...(!open && !isMobile && {
                    overflowX: 'hidden',
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                    width: theme.spacing(7),
                    [theme.breakpoints.up('sm')]: {
                        width: theme.spacing(7),
                    },
                }),
            },
        }),
    );

    return (
        <DrawerBuild variant="permanent" open={open}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon strokeWidth={1} />
                </IconButton>
            </Toolbar>
            {/* <Divider /> */}
            <List component="nav">

                <ListItemButton component={Link} to="dashboard" sx={theme.customStyles.drawerText} onClick={(event) => handleListItemClick(event, 0)} selected={selectedIndex === 0}>
                    <ListItemIcon sx={theme.customStyles.drawerIcon}>
                        <LayoutDashboardIcon size={theme.customStyles.drawerIcon.size} strokeWidth={theme.customStyles.drawerIcon.strokeWidth} />
                    </ListItemIcon>
                    <ListItemText disableTypography={true} primary="Dashboard" />
                </ListItemButton>

                <ListItemButton component={Link} to="swagitems" sx={theme.customStyles.drawerText} onClick={(event) => handleListItemClick(event, 1)} selected={selectedIndex === 1}>
                    <ListItemIcon sx={theme.customStyles.drawerIcon}>
                        <ShirtIcon size={theme.customStyles.drawerIcon.size} strokeWidth={theme.customStyles.drawerIcon.strokeWidth} />
                    </ListItemIcon>
                    <ListItemText disableTypography={true} primary="Swag Items" />
                </ListItemButton>

                <ListItemButton component={Link} to="inventory" sx={theme.customStyles.drawerText} onClick={(event) => handleListItemClick(event, 1)} selected={selectedIndex === 2}>
                    <ListItemIcon sx={theme.customStyles.drawerIcon}>
                        <SheetIcon size={theme.customStyles.drawerIcon.size} strokeWidth={theme.customStyles.drawerIcon.strokeWidth} />
                    </ListItemIcon>
                    <ListItemText disableTypography={true} primary="Inventory" />
                </ListItemButton>

                <ListItemButton component={Link} to="companies" sx={theme.customStyles.drawerText} onClick={(event) => handleListItemClick(event, 2)} selected={selectedIndex === 3}>
                    <ListItemIcon sx={theme.customStyles.drawerIcon}>
                        <BuildingIcon size={theme.customStyles.drawerIcon.size} strokeWidth={theme.customStyles.drawerIcon.strokeWidth} />
                    </ListItemIcon>
                    <ListItemText disableTypography={true} primary="Companies" />
                </ListItemButton>

                <ListItemButton component={Link} to="contacts" sx={theme.customStyles.drawerText} onClick={(event) => handleListItemClick(event, 3)} selected={selectedIndex === 4}>
                    <ListItemIcon sx={theme.customStyles.drawerIcon}>
                        <ContactIcon size={theme.customStyles.drawerIcon.size} strokeWidth={theme.customStyles.drawerIcon.strokeWidth} />
                    </ListItemIcon>
                    <ListItemText disableTypography={true} primary="Contacts" />
                </ListItemButton>

                <ListItemButton component={Link} to="disbursements" sx={theme.customStyles.drawerText} onClick={(event) => handleListItemClick(event, 4)} selected={selectedIndex === 5}>
                    <ListItemIcon sx={theme.customStyles.drawerIcon}>
                        <PackageIcon color={theme.customStyles.drawerIcon.color} size={theme.customStyles.drawerIcon.size} strokeWidth={theme.customStyles.drawerIcon.strokeWidth} />
                    </ListItemIcon>
                    <ListItemText disableTypography={true} primary="Disbursements" />
                </ListItemButton>

                {/* <ListItemButton component={Link} to="financial" sx={theme.customStyles.drawerText} onClick={(event) => handleListItemClick(event, 5)} selected={selectedIndex === 6}>
                    <ListItemIcon sx={theme.customStyles.drawerIcon}>
                        <BriefcaseIcon size={theme.customStyles.drawerIcon.size} strokeWidth={theme.customStyles.drawerIcon.strokeWidth} />
                    </ListItemIcon>
                    <ListItemText disableTypography={true} primary="Financial" />
                </ListItemButton> */}

                <ListItemButton component={Link} to="users" sx={theme.customStyles.drawerText} onClick={(event) => handleListItemClick(event, 6)} selected={selectedIndex === 7}>
                    <ListItemIcon sx={theme.customStyles.drawerIcon}>
                        <UsersIcon size={theme.customStyles.drawerIcon.size} strokeWidth={theme.customStyles.drawerIcon.strokeWidth} />
                    </ListItemIcon>
                    <ListItemText disableTypography={true} primary="Users" />
                </ListItemButton>

                <ListItemButton component={Link} to="settings" sx={theme.customStyles.drawerText} onClick={(event) => handleListItemClick(event, 7)} selected={selectedIndex ===8}>
                    <ListItemIcon sx={theme.customStyles.drawerIcon}>
                        <SettingsIcon size={theme.customStyles.drawerIcon.size} strokeWidth={theme.customStyles.drawerIcon.strokeWidth} />
                    </ListItemIcon>
                    <ListItemText disableTypography={true} primary="Settings" />
                </ListItemButton>

            </List>
        </DrawerBuild>
    );
}