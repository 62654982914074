import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Snackbar from '@mui/material/Snackbar';

import { Save as SaveIcon, Eye as EyeIcon, EyeOff as EyeOffIcon, XCircle as XCircleIcon } from 'lucide-react';

const SET_PASSWORD = gql`
    mutation ModifyPassword($currentpw: String, $pw: String, $confirmpw: String) {
        modifyPassword(currentpw: $currentpw, pw: $pw, confirmpw: $confirmpw) @connection(key: "modifyPassword", filter: []) {
            responseCode
            responseText
            actionType
            objectType
            objectId
		}
	}
`;

export default function Password() {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [serverErr, setServerErr] = useState(false);
    const [errCode, errCodeSet] = useState(false);

    const [openPwAlert, setOpenPwAlert] = useState(false);

    const handleClosePwAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenPwAlert(false);
    };

    const [showCurrentpw, setShowCurrentpw] = useState(false);
    const handleClickShowCurrentpw = () => setShowCurrentpw((show) => !show);
    const handleMouseDownCurrentpw = (event) => {
        event.preventDefault();
    };

    const [showPw, setShowPw] = useState(false);
    const handleClickShowPw = () => setShowPw((show) => !show);
    const handleMouseDownPw = (event) => {
        event.preventDefault();
    };

    const [showConfirmpw, setShowConfirmpw] = useState(false);
    const handleClickShowConfirmpw = () => setShowConfirmpw((show) => !show);
    const handleMouseDownConfirmpw = (event) => {
        event.preventDefault();
    };

    const [currentpwErr, setcurrentPwErr] = useState(false);
    const [currentpw, currentpwSet] = useState("");
    const handleChangecurrentpw = (event) => {
        currentpwSet(event.target.value);
    };

    const [pwErr, setPwErr] = useState(false);
    const [pw, pwSet] = useState("");
    const handleChangepw = (event) => {
        pwSet(event.target.value);
    };

    const [confirmpwErr, setconfirmpwErr] = useState(false);
    const [confirmpw, confirmpwSet] = useState("");
    const handleChangeconfirmpw = (event) => {
        confirmpwSet(event.target.value);
    };

    const updatePwFormData = () => {
        const formData = {};
        let formValid = true;

        setcurrentPwErr(false);
        setPwErr(false);
        setconfirmpwErr(false);
        errCodeSet(false);

        const containBox = document.getElementById("containBox");
        const scrollToError = () => { containBox.scrollIntoView({ behavior: "smooth" }); };

        if (!currentpw || currentpw === "") {
            setcurrentPwErr(true);
            formValid = false;
        }

        if (!pw || pw === "") {
            setPwErr(true);
            formValid = false;
        }

        if (!confirmpw || confirmpw === "") {
            setconfirmpwErr(true);
            formValid = false;
        }

        if (pw !== confirmpw) {
            setconfirmpwErr(true);
            formValid = false;
        }

        if (formValid) {
            setServerErr(false);
            formData.currentpw = currentpw;
            formData.pw = pw;
            formData.confirmpw = confirmpw;
            setIsLoading(true);
            updatePwExec({ variables: { ...formData } });
        }
        else {
            scrollToError();
            if (!errCode) {
                errCodeSet(true);
            }
        }

    }

    const [updatePwExec] = useMutation(SET_PASSWORD, {

        onCompleted: (setData) => {
            setIsLoading(false);
            const containBox = document.getElementById("containBox");
            const scrollToError = () => { containBox.scrollIntoView({ behavior: "smooth" }); };
            if (setData && setData?.modifyPassword?.responseCode === "200") {
                currentpwSet("");
                pwSet("");
                confirmpwSet("");
                setOpenPwAlert(true);
                navigate(`/logout`);
            }
            else {
                console.log(`err`);
                setServerErr(setData?.modifyPassword?.responseText);
                scrollToError();
            }
        },
        onError: (setError) => {
            const containBox = document.getElementById("containBox");
            const scrollToError = () => { containBox.scrollIntoView({ behavior: "smooth" }); };
            setIsLoading(false);
            setServerErr(`There was an error processing your request.`);
            scrollToError();
        }
    });

    return (
        <Box id="containBox">
            {isLoading &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress sx={{ color: '#c9fb00' }} thickness={1} size={60} /></Backdrop>
            }

            <Snackbar
                open={openPwAlert}
                anchorOrigin={{ vertical:'top', horizontal:'center' }}
                autoHideDuration={6000}
                onClose={handleClosePwAlert}
            >
                <Alert severity="success">
                    <AlertTitle>Password Updated Successfully</AlertTitle>
                </Alert>
            </Snackbar>

            {serverErr &&
                <Grid id="serverErrorInfo" container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px' }}>
                    <Grid item>
                        <Alert severity="error" variant="outlined">
                            <AlertTitle>Error</AlertTitle>
                            {serverErr}
                        </Alert>
                    </Grid>
                </Grid>
            }

            {errCode &&
                <Grid id="errorInfo" container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px' }}>
                    <Grid item>
                        <Alert severity="error" variant="outlined">
                            <AlertTitle>Error</AlertTitle>
                            Please resolve the form errors below!
                        </Alert>
                    </Grid>
                </Grid>
            }

            <Typography variant="h5">Change Password</Typography>

            <Stack component="form" spacing={2} autoComplete="off" sx={{ '& > :not(style)': { padding: '20px', paddingBottom: '30px' } }}>

                <FormControl sx={{ marginTop: '30px', maxWidth: '500px' }} error={currentpwErr} variant="standard">
                    <InputLabel htmlFor="currentpw">Current Password</InputLabel>
                    <Input
                        id="currentpw"
                        type={showCurrentpw ? 'text' : 'password'}
                        value={currentpw} onChange={handleChangecurrentpw}
                        fullWidth
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowCurrentpw}
                                    onMouseDown={handleMouseDownCurrentpw}
                                    edge="end"
                                >
                                    {showCurrentpw ? <EyeOffIcon strokeWidth={1} size={20} /> : <EyeIcon strokeWidth={1} size={20} />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Current Password"
                    />
                </FormControl>

                <FormControl sx={{ marginTop: '30px', maxWidth: '500px' }} error={pwErr} variant="standard">
                    <InputLabel htmlFor="pw">New Password</InputLabel>
                    <Input
                        id="pw"
                        type={showPw ? 'text' : 'password'}
                        value={pw} onChange={handleChangepw}
                        fullWidth
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPw}
                                    onMouseDown={handleMouseDownPw}
                                    edge="end"
                                >
                                    {showPw ? <EyeOffIcon strokeWidth={1} size={20} /> : <EyeIcon strokeWidth={1} size={20} />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="New Password"
                    />
                </FormControl>

                <FormControl sx={{ marginTop: '30px', maxWidth: '500px' }} error={confirmpwErr} variant="standard">
                    <InputLabel htmlFor="confirmpw">Confirm New Password</InputLabel>
                    <Input
                        id="confirmpw"
                        type={showConfirmpw ? 'text' : 'password'}
                        value={confirmpw} onChange={handleChangeconfirmpw}
                        fullWidth
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmpw}
                                    onMouseDown={handleMouseDownConfirmpw}
                                    edge="end"
                                >
                                    {showConfirmpw ? <EyeOffIcon strokeWidth={1} size={20} /> : <EyeIcon strokeWidth={1} size={20} />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Confirm New Password"
                    />
                </FormControl>

            </Stack>
            <Button aria-label="save form" variant="outlined" onClick={updatePwFormData} endIcon={<SaveIcon strokeWidth={1} size={16} />}>Update Password</Button>

        </Box >
    );
}