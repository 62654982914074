import React, { useEffect, useState, Fragment } from 'react';
import { gql, useMutation } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Avatar from '@mui/material/Avatar';
import { Trash2 as Trash2Icon } from 'lucide-react';
import { useTheme } from '@mui/material/styles';
import LoadingDisplay from '../common/LoadingDisplay';

const MODIFY_IMAGE = gql`
	mutation ModifyImage($businessId: String, $objectId: String, $imgType: String, $imgKey: String, $action: String) {
		modifyImage(businessId: $businessId, objectId: $objectId, imgType: $imgType, imgKey: $imgKey, action: $action){
            responseCode
            responseText
            actionType
            objectType
            objectId
		}
	}
`;

export default function ImageList(props) {
    const { imgType, images, sourceTypeId, refetchQuery, b2Auth, businessRid } = props;
    const uid = localStorage.getItem('uid');
    const [open, setOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertContent, setAlertContent] = useState("");
    const [remImgKey, setRemImgKey] = useState("");
    const [remImgSrc, setRemImgSrc] = useState("");
    const [imgList, setImgList] = useState([]);
    const theme = useTheme();

    let parsedImgList = [];

    const handleClickOpen = (imgKey,imgSrc) => {
        setRemImgKey(imgKey);
        setRemImgSrc(imgSrc);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setRemImgKey("");
        setRemImgSrc("");
    };

    const [imgAction, {loading}] = useMutation(MODIFY_IMAGE, {
        onCompleted: (imageData) => {
            if (imageData && imageData?.modifyImage?.responseCode === "200"){
                setAlertContent(<Alert severity="success"><AlertTitle>Image Modified</AlertTitle>The image has been modified successfully.</Alert>);
                setAlertOpen(true);
                handleClose();
                const newImgList = imageData.modifyImage.objectId;

                try{
                    const newImgListReplaced = newImgList.replace(/'/g, '"');
                    if(newImgListReplaced && newImgListReplaced !== "[]"){
                        const displayImagesPreSort = JSON.parse(newImgListReplaced);
                        const updateSorted = displayImagesPreSort.sort((a, b)=>{return a.displayOrder - b.displayOrder;});
                        buildImageList(updateSorted);
                        if(refetchQuery){refetchQuery();}
                    }
                    else{
                        handleClose();
                        buildImageList([]);
                        if(refetchQuery){refetchQuery();}
                    }
                }
                catch(error){
                    console.log(`imgAction build catch: ${error}`);
                    handleClose();
                    buildImageList([]);
                    if(refetchQuery){refetchQuery();}
                }
            }
        },
        onError: (error) => {
            console.log(`MODIFY_IMAGE - e- ${error}`);
            setAlertContent(<Alert severity="error"><AlertTitle>Image Modify Issue</AlertTitle>There was a problem modifying the image - please try again or contact us for support.</Alert>);
            setAlertOpen(true);
        }
    });

    const handleRemoveImage = () => {
        imgAction({variables: {businessId:businessRid,objectId:sourceTypeId,imgType:imgType,imgKey:remImgKey,action:'remove'}});
    }

    const buildImageList = (imageArr) => {
        let imageDisplay = [];

        {imageArr.length > 0 ? 
            imageArr.map((currentImage, index) => {
                let imgSource = "";
                const imgSrcVal = imageArr[index]["key"];
                if(imgSrcVal){
                    imgSource = `https://f004.backblazeb2.com/file/swagmanager/docs/swag/clients/${businessRid}/images/${imgType}_${sourceTypeId}_s_${imgSrcVal}?Authorization=${b2Auth}`;
                }

                const currentImgRender =
                    <Grid container item direction="row" alignItems="center" justifyContent="space-between" key={index} 
                        sx={{padding: '8px',  }}
                    >
                        <Grid item>
                            <Avatar alt={`Product Image`} variant="square" src={imgSource} sx={{ width: '100px', height: '100px' }}/>
                        </Grid>
                        <Grid item>
                            <IconButton edge="end" aria-label="delete" onClick={()=>handleClickOpen(imgSrcVal,imgSource)}>
                                <Trash2Icon color="red" strokeWidth={1} />
                            </IconButton>
                        </Grid>
                    </Grid>;
                imageDisplay.push(currentImgRender);
            })
            :
            imageDisplay.push(<Typography key={1}>No images found</Typography>);
        }
        setImgList(imageDisplay);
    }

    useEffect(() => {
        if(images && images !== "[]"){
            buildImageList(images);
        }
	}, [images]);

    return (
        <Grid container sx={{padding: '12px'}}>
            <Typography><strong>Current Images</strong></Typography>

            <Collapse in={alertOpen}>
                {alertContent}
            </Collapse>

            {imgList && imgList.length > 0 ?
            imgList
            :
            <Typography> : No images found</Typography>
            }

            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
                <DialogTitle>Remove Image</DialogTitle>

                {loading ? 
                    <DialogContent>
                        <LoadingDisplay />
                    </DialogContent>
                    :
                    <Fragment>
                    <DialogContent>
                        <Grid container direction="column" alignItems="center" justifyContent="center">
                            <Grid item>
                                <Avatar alt={`Product Image`} variant="square" src={remImgSrc} sx={{ width: '100px', height: '100px' }}/>
                            </Grid>
                            <Grid item>
                                <DialogContentText>
                                    Are you sure you want to remove this image?
                                </DialogContentText>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                        <Button variant="outlined" onClick={handleRemoveImage}>Remove Image</Button>
                    </DialogActions>
                    </Fragment>
                }

            </Dialog>

        </Grid>

    );
}