import React, { useMemo, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Image as ImageIcon } from 'lucide-react';
import { useTheme } from '@mui/material/styles';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '1px',
    borderRadius: 2,
    borderColor: '#ccc',
    borderStyle: 'dashed',
    // backgroundColor: '#fafafa',
    color: '#ccc',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export default function ImageDrop(props) {
    const {setImgFile} = props;
    const theme = useTheme();
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ maxFiles: 1, accept: {
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg','.jpg'],
        'image/bmp': ['.bmp'],
        'image/webp': ['.webp'],
        'image/svg+xml': ['.svg'],
        'image/gif': ['.gif'],
    }, });
    // } = useDropzone({ maxFiles: 1, accept: 'image/jpeg, image/png, image/bmp, image/webp' });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const acceptedFileItems = acceptedFiles.map(file => {
        return (
            <Alert key={file.path} severity="success" variant="outlined" icon={<ImageIcon size={20} strokeWidth={1} />}>
                Selected File: {file.path} - {file.size} bytes
            </Alert>
        )}
    );

    useEffect(() => {
        if(acceptedFiles.length > 0){
            setImgFile(acceptedFiles);
        }
    }, [acceptedFiles,setImgFile]);

    const fileRejectionItems = fileRejections.map(({ file, errors }) => {
        return (
        <Alert key={file.path} severity="error" variant="outlined">
            Invalid File: {file.path} - {file.size} bytes
            <div>{errors.map(e => (<span key={e.code}>{e.message}</span>))}</div>
        </Alert>
        )}
    );

    return (
        <Box sx={{padding: '12px'}}>
            <AlertTitle><strong>Image upload</strong></AlertTitle>
            <div className="container">
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>Drag and drop a file here, or click to select files</p>
                    <em>(Only *.jpeg, *.jpg, *.webp, *.bmp, *.gif, *.svg, and *.png images are valid)</em>
                </div>
                <aside>
                    {acceptedFileItems && 
                        <div>{acceptedFileItems}</div>
                    }
                    {fileRejectionItems && 
                        <div>{fileRejectionItems}</div>
                    }
                </aside>
            </div>
        </Box>

    );
}